import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import ContactForm from "../../components/ContactForm"
import Address from "../../components/addressElement"

import { GatsbyImage, getImage } from "gatsby-plugin-image"


function ContactPage ({ data }) {
  const image = getImage(data.mapImage.childImageSharp.gatsbyImageData)
  return (
    <Layout
      headerTitle="Unsere Standorte"
      headerSub='Kontakt'
      bgImg='kontakt'>
      <Seo title="Kontakt"/>
    <div className="max-w-6xl mx-auto">

          <iframe title='Standorte' className="relative hidden object-contain w-full -mt-12 md:block md:h-720 h-400 md:-mt-24" src="https://snazzymaps.com/embed/279802" />
          <GatsbyImage image={image} alt=""
          className="relative object-contain -mt-12 bg-black md:hidden lg:h-720 h-400 md:-mt-24"/>

        <div className="grid max-w-lg grid-cols-1 gap-12 px-6 py-24 mx-auto text-base uppercase md:grid-cols-2">
          <Address className='col-span-2 lg:col-span-1' location='Thusis' tel='+41 (0)81 650 00 30' email='info@hossmann.com' name='Hossmann &amp; Sohn AG' address1='Compognastrasse 33' address2='CH-7430 Thusis'/>
          <Address className='col-span-2 lg:col-span-1' location='Zürich' tel='+41 (0)44 434 10 40' email='info@hossmann.com' name='Hossmann Zürich AG' address1='Gutstraße 12' address2='8055 Zürich'/>
        </div>

      <div className="max-w-lg mx-auto md:pt-16">
          <ContactForm/>
      </div>
    
    </div>

    </Layout>
  );
 }
export default ContactPage;

export const pageQuery = graphql`{
  mapImage: file(relativePath: {eq: "map.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
}
`